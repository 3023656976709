import React from 'react';

import {
    Box,
    Flex,
    Table,
    Tbody,
    Td,
    Tr,
    Text,
    Tooltip,
} from '@chakra-ui/react';
import { PropTypes } from 'prop-types';

import '../../assets/css/index.css';
import CommonTableHeading from '../../common/CommonTableHeading';
import { TABLE_FIELD_PADDING } from '../../constants/commonConstant';
import {
    getCurrentDate,
    formatDateByZone,
    getHolidayStart,
} from '../../helper/dateHelper/dateUtils.js';
import HolidayDeleteModal from './HolidayDeleteModal';
import HolidayEditModal from './HolidayEditModal';

const DATE = ['200px', '210px', '220px'];
const HOLIDAY = '200px';
const DAYS = ['400px', '200px'];
const ACTIONS = '96px';

function HolidayListTable({
    results,
    newAddHoliday,
    onChange,
    onHolidayDeleted,
}) {
    const TABLE_HEADINGS = ['Date', 'Holiday', 'Days', 'Actions'];

    const currentDate = getCurrentDate();

    return (
        <Flex
            mt="8px"
            direction="column"
            fontSize={{ lg: '12px', base: '12px' }}
        >
            <Box overflow="hidden">
                <Box overflow="auto">
                    <Table variant="simple" justify="center" align="center">
                        <CommonTableHeading tableHeadings={TABLE_HEADINGS} />
                        <Tbody>
                            {results.map((result, index) => {
                                const holidayStart = getHolidayStart(
                                    result.startFrom
                                );

                                if (!holidayStart.isValid()) {
                                    return null;
                                }

                                const isPast =
                                    holidayStart.isBefore(currentDate);

                                const disabledRowStyles = isPast
                                    ? {
                                          opacity: 0.5,
                                          cursor: 'not-allowed',
                                          color: '#909090 !important',
                                      }
                                    : {};

                                return (
                                    <Tr
                                        key={result.id}
                                        bgColor={
                                            newAddHoliday &&
                                            newAddHoliday === result.id
                                                ? '#CCECFF'
                                                : index & 1
                                                ? '#F2F2F2'
                                                : '#FFFFFF'
                                        }
                                        cursor={
                                            isPast ? 'not-allowed' : 'pointer'
                                        }
                                        _hover={{
                                            bg: '#0077C0 !important',
                                            color: '#FFFFFF !important',
                                        }}
                                        style={disabledRowStyles}
                                    >
                                        <Td
                                            p={TABLE_FIELD_PADDING}
                                            minW={DATE}
                                            w={DATE}
                                        >
                                            {formatDateByZone(
                                                result.startFrom,
                                                result.endAt
                                            )}
                                        </Td>
                                        <Td
                                            p={TABLE_FIELD_PADDING}
                                            minW={HOLIDAY}
                                            w={HOLIDAY}
                                        >
                                            {result.name}
                                        </Td>
                                        <Td
                                            p={TABLE_FIELD_PADDING}
                                            minW={DAYS}
                                            w={DAYS}
                                        >
                                            {result.count}
                                        </Td>
                                        <Td
                                            p={TABLE_FIELD_PADDING}
                                            minW={ACTIONS}
                                            w={ACTIONS}
                                        >
                                            <Tooltip
                                                className="custom-tooltip"
                                                label={
                                                    isPast
                                                        ? 'Past holiday can’t be edited or deleted.'
                                                        : ''
                                                }
                                                isDisabled={!isPast}
                                            >
                                                <Flex>
                                                    <Text pr="8px">
                                                        <HolidayDeleteModal
                                                            holidayDelete={
                                                                result
                                                            }
                                                            onHolidayDeleted={
                                                                onHolidayDeleted
                                                            }
                                                        />
                                                    </Text>
                                                    <Text>
                                                        <HolidayEditModal
                                                            holidayEdit={result}
                                                            onChange={onChange}
                                                        />
                                                    </Text>
                                                </Flex>
                                            </Tooltip>
                                        </Td>
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>
                </Box>
            </Box>
        </Flex>
    );
}

HolidayListTable.propTypes = {
    results: PropTypes.array.isRequired,
    newAddHoliday: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onHolidayDeleted: PropTypes.func.isRequired,
};

export default HolidayListTable;
