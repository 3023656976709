import React, { useEffect, useState } from 'react';

import { Box, Flex, Spacer, Text, useToast } from '@chakra-ui/react';

import CustomBreadcrumb from '../../common/CustomBreadcrumb.js';
import DataLoader from '../../common/DataLoader.js';
import PageTitle from '../../common/PageTitle.js';
import { SET_HOLIDAY } from '../../constants/actionTypeConstant.js';
import { HOLIDAY_BREADCRUMB } from '../../constants/breadcrumbData.js';
import { useInformation } from '../../contexts/informationContext.js';
import {
    getEndOfYear,
    getStartOfYear,
} from '../../helper/dateHelper/dateUtils.js';
import YearPicker from '../../report/components/YearPicker.jsx';
import HolidayManagement from '../../service/holiday-management.js';
import HolidayAddModal from '../components/HolidayAddModal.js';
import HolidayListTable from '../components/HolidayListTable.js';
import NoHoliday from '../components/NoHoliday.js';

export default function HolidayDashboard() {
    const currentYear = new Date().getFullYear();
    const toast = useToast();
    const [selectedYear, setSelectedYear] = useState(null);
    const [newAddHoliday, setNewAddHoliday] = useState();
    const [holidays, setHolidays] = useState([]);
    const [isDataLoaderOpen, setDataLoaderOpen] = useState(false);
    const PAGE_TITLE = `Holidays of ${selectedYear}`;
    const { state, dispatch } = useInformation();

    const pickedYear = year => {
        setSelectedYear(year);
        fetchHolidaysData(year);
    };

    useEffect(() => {
        setSelectedYear(currentYear);
        fetchHolidaysData(currentYear);
        return () => {
            dispatch({ type: SET_HOLIDAY, payload: { content: [] } });
        };
    }, []);
    async function fetchHolidaysData(year) {
        setDataLoaderOpen(true);
        try {
            const start = getStartOfYear(year);
            const end = getEndOfYear(year);
            const response = await HolidayManagement.findHolidays(start, end);
            const responseData = response;
            setHolidays(responseData);
            setDataLoaderOpen(false);
            dispatch({ type: SET_HOLIDAY, payload: response });
            return true;
        } catch (error) {
            setDataLoaderOpen(false);
            toast({
                title: 'Failed to fetch holidays',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return false;
        }
    }
    const newDataHandler = data => {
        const loader = fetchHolidaysData(selectedYear);

        if (loader) {
            setNewAddHoliday(data.id);
            setTimeout(() => {
                setNewAddHoliday(null);
            }, 10000);
        }
    };
    const handleHolidayDeleted = deletedHolidayId => {
        setHolidays(prevHolidays => ({
            ...prevHolidays,
            data: prevHolidays.data.filter(
                holiday => holiday.id !== deletedHolidayId
            ),
        }));
    };

    if (!holidays || isDataLoaderOpen) {
        return <DataLoader isOpen={isDataLoaderOpen} />;
    }

    return (
        <>
            <CustomBreadcrumb allBreadcrumbData={HOLIDAY_BREADCRUMB} />
            <Flex direction="row" justify="space-between">
                <Box>
                    <PageTitle title={PAGE_TITLE} />
                    <Text color="#7A7C81" fontWeight="300">
                        <Text as="span" color="#0077C0" fontWeight="600">
                            {holidays.totalHolidaysCount}{' '}
                        </Text>
                        {''}holidays this year where{' '}
                        <Text as="span" color="#0077C0" fontWeight="600">
                            {holidays.holidayLeft}{' '}
                        </Text>
                        is left.
                    </Text>
                </Box>

                <Spacer />

                <Flex gap="8px">
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <HolidayAddModal onChange={newDataHandler} />
                    </Box>
                    <YearPicker
                        onChange={e => pickedYear(e)}
                        selectedYear={selectedYear}
                    />
                </Flex>
            </Flex>
            <Box pt="24px" maxHeight="80vh">
                {!holidays?.data || holidays?.data?.length === 0 ? (
                    <NoHoliday />
                ) : (
                    <HolidayListTable
                        results={holidays?.data}
                        newAddHoliday={newAddHoliday}
                        onChange={newDataHandler}
                        onHolidayDeleted={handleHolidayDeleted}
                    />
                )}
            </Box>
        </>
    );
}
