import Service from './index';

const HOLIDAY_SEARCH_PATH = '/admin/holiday';
export default class HolidayManagement {
    static async findHolidays(startFrom, endAt) {
        const params = new URLSearchParams({
            start: startFrom,
            end: endAt,
        }).toString();
        return Service.get(`${HOLIDAY_SEARCH_PATH}?${params}`, {
            data: {},
        }).then(response => response.data);
    }
    static async addHolidays(payload) {
        return Service.post(`${HOLIDAY_SEARCH_PATH}`, payload).then(
            response => response.data
        );
    }
    static async updateHoliday(payload) {
        return Service.put(`${HOLIDAY_SEARCH_PATH}`, payload).then(
            response => response.data
        );
    }
    static async deleteHoliday(id) {
        return Service.delete(`${HOLIDAY_SEARCH_PATH}/${id}`).then(
            response => response.data
        );
    }
}
