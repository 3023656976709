import React from 'react';

import { Flex, Text } from '@chakra-ui/react';

export default function NoHoliday() {
    return (
        <>
            <Flex
                justify="center"
                align="center"
                height="100%"
                minHeight="80vh"
            >
                <Text color="#56607A" fontSize="22px" fontWeight="400">
                    No Holidays are created for the selected year.
                </Text>
            </Flex>
        </>
    );
}
