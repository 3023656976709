import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Flex, Spacer, Text, useToast } from '@chakra-ui/react';

import CommonPagination from '../../common/CommonPagination';
import CustomBreadcrumb from '../../common/CustomBreadcrumb';
import DataLoader from '../../common/DataLoader';
import PageTitle from '../../common/PageTitle';
import * as Type from '../../constants/actionTypeConstant';
import { LEAVE_LIST_BREADCRUMB } from '../../constants/breadcrumbData';
import { PERMANENT } from '../../constants/employeeTypes';
import { buildLeavesPath } from '../../constants/pathNameConstants';
import { NUMBER_OF_EMPLOYEE_FILTERED_BY_NAME } from '../../constants/reportManagementConstants';
import { useInformation } from '../../contexts/informationContext';
import { currentDate, startingFromCurrentYear } from '../../helper/utils';
import EmployeeManagement from '../../service/employee-management';
import PartnerManagement from '../../service/partner-management';
import ReportManagement from '../../service/reports-management';
import AllEmployeeLeaveData from '../components/AllEmployeeLeaveData';
import ApprovedLeaveChart from '../components/ApprovedLeaveChart';
import EmployeeLeaveResults from '../components/EmployeeLeaveResults';
import FilterOptions from '../components/FilterOptions';
import FilteredData from '../components/FilteredData';
import YearPicker from '../components/YearPicker';
import ForecastedLeave from '../components/forecasted-leave/ForecastedLeaveBarChartView';

const PAGE_TITLE = 'Employee Leave Details';

const LeaveList = () => {
    const {
        state: {
            filterByEmployeesNameForLeave,
            isFilterOptionOpenForLeave,
            filterByDesignationForLeave,
            employeesLeaveResult,
        },
        dispatch,
    } = useInformation();
    const navigate = useNavigate();
    const toast = useToast();
    let { page, size } = useParams();
    const [leaveResults, setLeaveResult] = useState(employeesLeaveResult);
    const [employeesByName, setEmployeesByName] = useState([]);
    const [partners, setPartners] = useState([]);
    const [leads, setLeads] = useState([]);

    const [partner, setPartner] = useState(null);
    const [designations, setDesignations] = useState([]);
    const [dateRange, setDateRange] = useState([
        startingFromCurrentYear(),
        currentDate(),
    ]);
    const [isDataLoaderOpen, setDataLoaderOpen] = useState(false);
    const [csvData, setCsvData] = useState(false);
    const csvInstance = useRef();
    const partnerId = partner
        ? partners.find(p => p.name === partner).id
        : null;

    const onDateRangeChangeHandler = dateRange => {
        setDateRange(dateRange);
    };

    useEffect(() => {
        if (csvData && csvInstance.current && csvInstance.current.link) {
            csvInstance.current.link.click();
            setCsvData(false);
        }
    }, [csvData]);

    const exportCsv = async () => {
        const employeesId = filterByEmployeesNameForLeave.map(
            e => e.employeeId
        );
        try {
            const payload = {
                startFrom: dateRange[0],
                endAt: dateRange[1],
                employeeIdList: employeesId,
                designationList: filterByDesignationForLeave,
                partnerId: partnerId,
            };
            const response = await ReportManagement.leaveReportDownload(
                payload
            );
            const leaveResultObject = [];
            for (let i = 0; i < response.length; i++) {
                const {
                    employeeName,
                    employeeId,
                    designation,
                    partner,
                    totalLeave,
                    unplannedLeave,
                    monthlyAverageLeave,
                    yearlyForecastedLeave,
                    leaveComparison,
                } = response[i];

                const individualRow = {
                    ['Employee Name']: employeeName,
                    ['Employee Id']: employeeId,
                    ['Designation']: designation,
                    ['Partner']: partner.name,
                    ['Total Leave']: totalLeave,
                    ['Unplanned Leave']: unplannedLeave,
                    ['Monthly Average']: monthlyAverageLeave,
                    ['Yearly Forecast']: yearlyForecastedLeave,
                    ['Leave Comparison']: leaveComparison + '%',
                };
                leaveResultObject.push(individualRow);
            }
            setCsvData(leaveResultObject);
        } catch (error) {
            setCsvData([]);
        }
    };
    const findPartner = async () => {
        try {
            const allPartners = await PartnerManagement.getAllPartner();
            setPartners(allPartners.data);
        } catch (error) {
            toast({
                title: 'Fetching failed',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        findPartner();
    }, []);

    const findLeads = async () => {
        try {
            const allLeads = await EmployeeManagement.getAllLeads();
            setLeads(allLeads);
        } catch (error) {
            toast({
                title: 'Fetching failed',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };
    useEffect(() => {
        findLeads();
    }, []);

    if (!leaveResults) return <DataLoader isOpen={isDataLoaderOpen} />;

    const pickedYear = e => {
        console.log(e);
    };

    const forecastedLeaveData = e => {
        console.log(e);
    };
    const leadsAndPatnersPicker = e => {
        console.log(e);
    };

    return (
        <Box>
            <CustomBreadcrumb allBreadcrumbData={LEAVE_LIST_BREADCRUMB} />
            <Flex direction="row">
                <Box>
                    <PageTitle title={PAGE_TITLE} />
                    <Text color="#6C727F">
                        All information reflects the Current Year.
                    </Text>
                </Box>

                <Spacer />
                <Box>
                    <YearPicker onChange={e => pickedYear(e)} />
                </Box>
            </Flex>
            <Box py="24px">
                <Flex direction="row" gap="3" justifyContent="space-between">
                    <ForecastedLeave
                        partners={partners}
                        leads={leads}
                        forecastedLeaveData={e => forecastedLeaveData(e)}
                        leadsAndPatnersPicker={e => leadsAndPatnersPicker(e)}
                    />
                    <ApprovedLeaveChart />
                </Flex>
            </Box>

            {/* <Box>
                <AllEmployeeLeaveData />
            </Box> */}

            {/* {isFilterOptionOpenForLeave ? (
                <Box>
                    <FilterOptions
                        nameList={filterByEmployeesNameForLeave}
                        employeesByName={employeesByName}
                        setEmployeesByName={setEmployeesByName}
                        setNameListQueryParameter={setNameListQueryParameter}
                        employeeSearchByName={employeeSearchByName}
                        partners={partners}
                        selectedPartner={partner}
                        setSelectedPartner={setSelectedPartner}
                        selectedDesignationsLength={
                            filterByDesignationForLeave.length
                        }
                        designationsList={designations}
                        setDesignationListQueryParameter={
                            setDesignationListQueryParameter
                        }
                    />
                    <FilteredData
                        nameList={filterByEmployeesNameForLeave}
                        removeNameFromNameListQueryParameter={
                            removeNameFromNameListQueryParameter
                        }
                        selectedPartner={partner}
                        setSelectedPartner={setSelectedPartner}
                        designationList={filterByDesignationForLeave}
                        removeDesignationFromDesignationListQueryParameter={
                            removeDesignationFromDesignationListQueryParameter
                        }
                    />
                </Box>
            ) : null} */}
            {/* <Text fontSize="12px" fontWeight="300" mt={2} mb={2}>
                Showing result for{' '}
                <b>
                    {dateRange[0]} - {dateRange[1]}
                </b>
            </Text> */}
            {/* <EmployeeLeaveResults results={employeesLeaveResult} />
            {employeesLeaveResult.totalPages > 1 && (
                <CommonPagination
                    totalPages={employeesLeaveResult.totalPages ?? 1}
                    setPageNo={setPageNumber}
                    page={Number(page)}
                    size={Number(size)}
                />
            )} */}
            {/* <Box mt={2} mb={7}>
                {employeesLeaveResult?.content?.length > 0 ? (
                    <Text fontSize="12px" fontWeight="300">
                        <b>Current Company Average </b>=
                        {` ${employeesLeaveResult.content[0].companyAverageLeave} `}
                        Days leave/year
                    </Text>
                ) : null}
            </Box> */}
        </Box>
    );
};

export default LeaveList;
