//auth
export const _ROOT = '/';
export const LOGIN_PATH = '/login';
export const HOME_PATH = '/home';

// employees
export const EMPLOYEES_PATH = '/employees/:page/:size';
export const buildEmployeesPath = (page, size) => `/employees/${page}/${size}`;
export const EMPLOYEE_ADD_PATH = '/employee/add';
export const EMPLOYEE_PATH = '/employee/:id';
export const buildEmployeePath = id => `/employee/${id}`;

// teams
export const TEAMS_PATH = '/teams/:page/:size';
export const buildTeamsPath = (page, size) => `/teams/${page}/${size}`;
export const TEAM_DETAILS_PATH = '/team/details/:id';
export const buildTeamDetailsPath = id => `/team/details/${id}`;

// leaves report
export const LEAVES_PATH = '/leaves/:page/:size';
export const buildLeavesPath = (page, size) => `/leaves/${page}/${size}`;
export const LEAVE_PATH = '/leave/:id';
export const buildLeavePath = id => `/leave/${id}`;

// admin
export const ADMIN_ADD_PATH = '/admin/add';

//holiday
export const HOLIDAY = '/holidays';
