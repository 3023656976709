import { Box, Flex, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { textStyle } from '../commonStyle/commonLeaveStyle';

export const ForecastedLeaveDistributionHeader = ({
    distributionType,
    selectedDistributionType,
    setDistributionType,
}) => {
    return (
        <Box pb="30px">
            <Flex
                justifyContent="space-between"
                alignItems="center"
                height="32px"
                pb="16px"
            >
                <Text {...textStyle}>Forecasted Leave Distribution</Text>
                <Box>
                    <Flex bg="#F9FAFB" borderRadius="4px">
                        {distributionType.map(item => (
                            <Text
                                key={item.key}
                                fontSize="12px"
                                fontWeight="500"
                                p="8px"
                                cursor="pointer"
                                className="toggle"
                                my="4px"
                                mx="4px"
                                bg={`${
                                    item === selectedDistributionType
                                        ? '#ffffff'
                                        : ''
                                } `}
                                color={`${
                                    item === selectedDistributionType
                                        ? 'blue'
                                        : '#9EA3AE '
                                } `}
                                borderRadius="4px"
                                onClick={() => setDistributionType(item)}
                            >
                                {item.label}
                            </Text>
                        ))}
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );
};

export default ForecastedLeaveDistributionHeader;

ForecastedLeaveDistributionHeader.propTypes = {
    distributionType: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        })
    ).isRequired,
    selectedDistributionType: PropTypes.object.isRequired,
    setDistributionType: PropTypes.func.isRequired,
};
