import React, { useCallback, useState } from 'react';

import { Box, Text } from '@chakra-ui/react';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';

import CustomTooltip from './chart/CustomTooltip';
import RenderActiveShape from './chart/RenderActiveShape';
import { borderStyle, textStyle } from './commonStyle/commonLeaveStyle';
import './styles.css';

const data = [
    { name: 'Auto Approved Leave', value: 30 },
    { name: 'Approved Leave', value: 70 },
];
const COLORS = ['#FF8C21', '#0077C0'];

const ApprovedLeaveChart = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const onPieEnter = useCallback(
        (_, index) => {
            setActiveIndex(index);
        },
        [setActiveIndex]
    );

    return (
        <Box {...borderStyle}>
            <Text {...textStyle}>Total Approved & Auto-approved Leaves</Text>
            <PieChart width={490} height={400}>
                <Pie
                    activeIndex={activeIndex}
                    activeShape={<RenderActiveShape />}
                    data={data}
                    cx={250}
                    cy={300}
                    startAngle={180}
                    endAngle={0}
                    innerRadius={150}
                    outerRadius={230}
                    fill="#F9FAFB"
                    dataKey="value"
                    onMouseEnter={onPieEnter}
                >
                    {data.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                        />
                    ))}
                </Pie>
                <Legend />
                <Tooltip content={<CustomTooltip />} />
            </PieChart>
        </Box>
    );
};

export default ApprovedLeaveChart;
