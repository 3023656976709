import * as yup from 'yup';

import * as ValidationMessage from '../constants/formValidationConstant';

export const LoginPageValidation = yup.object({
    username: yup
        .string()
        .required(ValidationMessage.EMAIL_REQUIRED_ERROR)
        .matches(
            ValidationMessage.EMAIL_REGEX,
            ValidationMessage.EMAIL_FORMAT_ERROR
        ),

    password: yup
        .string()
        .required(ValidationMessage.PASSWORD_REQUIRED_ERROR)
        .max(50, ValidationMessage.PASSWORD_LENGTH_ERROR)
        .min(8, ValidationMessage.PASSWORD_LENGTH_ERROR),
});

export const addEmployeeValidation = yup.object({
    email: yup
        .string()
        .required(ValidationMessage.EMAIL_REQUIRED_ERROR)
        .matches(
            ValidationMessage.EMAIL_REGEX,
            ValidationMessage.EMAIL_FORMAT_ERROR
        ),

    employeeId: yup
        .string()
        .required(ValidationMessage.EMPLOYEE_ID_REQUIRED_ERROR),

    name: yup.string().required(ValidationMessage.NAME_REQUIRED_ERROR),

    designation: yup
        .string()
        .required(ValidationMessage.DESIGNATION_REQUIRED_ERROR),

    status: yup.string().required(ValidationMessage.STATUS_REQUIRED_ERROR),

    supervisorId: yup
        .string()
        .required(ValidationMessage.SUPERVISOR_REQUIRED_ERROR),

    partnerId: yup.string().required(ValidationMessage.PARTNER_REQUIRED_ERROR),
});

export const addTeamValidation = yup.object({
    name: yup
        .string()
        .required(ValidationMessage.TEAM_NAME_REQUIRED_ERROR)
        .max(50, ValidationMessage.TEAM_NAME_LENGTH_ERROR)
        .min(3, ValidationMessage.TEAM_NAME_LENGTH_ERROR),

    status: yup.string().required(ValidationMessage.TEAM_STATUS_REQUIRED_ERROR),
});

export const addTeamMemberValidation = yup.object({
    name: yup
        .string()
        .required(ValidationMessage.MEMBER_NAME_REAUIRED_ERROR)
        .max(50, ValidationMessage.MEMBER_NAME_LENGTH_ERROR)
        .min(3, ValidationMessage.MEMBER_NAME_LENGTH_ERROR),
});

export const addAdminValidation = yup.object({
    email: yup
        .string()
        .required(ValidationMessage.EMAIL_REQUIRED_ERROR)
        .matches(
            ValidationMessage.EMAIL_REGEX,
            ValidationMessage.EMAIL_FORMAT_ERROR
        ),

    name: yup
        .string()
        .required(ValidationMessage.ADMIN_NAME_REQUIRED_ERROR)
        .max(50, ValidationMessage.ADMIN_NAME_LENGTH_ERROR)
        .min(3, ValidationMessage.ADMIN_NAME_LENGTH_ERROR),

    role: yup.string().required(ValidationMessage.ADMIN_ROLE_REQUIRED_ERROR),
});

export const addHolidayValidation = yup.object({
    name: yup
        .string()
        .required(ValidationMessage.HOLIDAY_NAME_REQUIRED_ERROR)
        .max(50, ValidationMessage.HOLIDAY_NAME_LENGTH_ERROR)
        .min(3, ValidationMessage.HOLIDAY_NAME_LENGTH_ERROR),
});
