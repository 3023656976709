import PropTypes from 'prop-types';
import { Sector } from 'recharts';

const RenderActiveShape = props => {
    const {
        cx,
        cy,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
    } = props;
    return (
        <g>
            <text
                x={cx}
                y={cy}
                dy={-35}
                fontSize="78px"
                fill="#6C727F"
                textAnchor="middle"
                fontWeight="700"
            >
                {payload.value}
            </text>
            <text x={cx} y={cy} dy={-15} textAnchor="middle" fill="#4D5461">
                {payload.name}
            </text>

            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
        </g>
    );
};

RenderActiveShape.propTypes = {
    cx: PropTypes.number,
    cy: PropTypes.number,
    innerRadius: PropTypes.number,
    outerRadius: PropTypes.number,
    startAngle: PropTypes.number,
    endAngle: PropTypes.number,
    fill: PropTypes.string,
    payload: PropTypes.array,
};

export default RenderActiveShape;
