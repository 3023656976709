export const borderStyle = {
    border: '1px',
    borderColor: '#BDBDBD',
    shadow: '0px 4px 8px 0px rgba(100, 100, 102, 0.10)',
    borderRadius: '4px',
    px: '24px',
    py: '24px',
};

export const textStyle = {
    color: '#000',
    fontSize: '14px',
    fontWeight: '600',
};
