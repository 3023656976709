import React, { useState } from 'react';

import { Box, Checkbox, Flex, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import downArrow from '../../assets/icons/arrow-down.svg';

const CustomDropDown = ({ data, placeholder, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [checkedItems, setCheckedItems] = useState({});

    const toggle = () => {
        setIsOpen(isOpen => !isOpen);
    };

    const handleLeadsAndPatnersPicker = e => {
        onChange(e.target);
        setCheckedItems({
            ...checkedItems,
            [e.target.value]: e.target.checked,
        });
    };

    return (
        <Box position="relative">
            <Flex
                width="208px"
                justifyContent="space-between"
                border="1px"
                borderColor="rgba(17, 17, 19, 0.20)"
                borderRadius="4px"
                padding="9px"
                onClick={toggle}
            >
                <Text
                    color="rgba(17, 17, 19, 0.60)"
                    fontSize="14px"
                    fontWeight="400"
                >
                    {placeholder}
                </Text>
                <img src={downArrow} alt="" />
            </Flex>
            <Box>
                {isOpen && (
                    <Box
                        position="absolute"
                        width="272px"
                        minHeight="192px"
                        maxHeight="242px"
                        padding="12px"
                        borderRadius="4px"
                        border="1px"
                        borderColor="#D3D5DA"
                        background="#ffffff"
                        boxShadow="0px 4px 8px 0px rgba(100, 100, 102, 0.10)"
                        overflow="scroll"
                        zIndex="10"
                        top="50px"
                    >
                        {data.map(item => {
                            return (
                                <Checkbox
                                    key={item.id}
                                    as="div"
                                    w="100%"
                                    gap="8px"
                                    padding="12px"
                                    _hover={{ bg: '#F9FAFB' }}
                                    cursor="pointer"
                                    border="1.5px"
                                    borderColor="#D3D5DA"
                                    borderRadius="2.667px"
                                    value={item.name}
                                    checked={checkedItems[item.name]}
                                    onChange={e =>
                                        handleLeadsAndPatnersPicker(e)
                                    }
                                >
                                    <Text
                                        color="rgba(17, 17, 19, 0.60)"
                                        fontSize="14px"
                                        fontWeight="400"
                                    >
                                        {item.name}
                                    </Text>
                                </Checkbox>
                            );
                        })}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

CustomDropDown.propTypes = {
    data: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default CustomDropDown;
