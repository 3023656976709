import React, { useState } from 'react';

import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Label,
} from 'recharts';

import CustomDropDown from '../../../common/dropDown/CustomDropDown';
import CustomTooltip from '../chart/CustomTooltip';
import { borderStyle } from '../commonStyle/commonLeaveStyle';
import '../styles.css';
import ForecastedLeaveDistributionHeader from './ForecastedLeaveDistributionHeader';

const data = [
    {
        property: 'tillTwenty',
        employeeCount: 25,
        fill: '#12B76A',
        label: 'Till 20',
    },
    {
        property: 'twentyOneToTwentyFive',
        employeeCount: 8,
        fill: '#FF8C21',
        label: '21 - 25',
    },
    {
        property: 'moreThanTwentyFive',
        employeeCount: 3,
        fill: '#E30000',
        label: 'Above 25',
    },
];

const leads = [
    {
        employeeId: 'ESE019DHK',
        name: 'Rakibul Huda',
        designation: 'Software Engineer',
    },
    {
        employeeId: 'ECEO031DHK',
        name: 'Sahib Bin Mahboob',
        designation: 'Chief Executive Officer',
    },
    {
        employeeId: 'ESSE004DHK',
        name: 'Yousha Farokey',
        designation: 'Software Engineer',
        profilePicture:
            '/files/profile-picture/ESSE004DHK_1bdb5050-cc3a-11ec-bc70-170a8bebdad1.jpg',
    },
    {
        employeeId: 'ESE064DHK',
        name: 'Mohd. Asfaq-E-Azam Rifat',
        designation: 'Software Engineer',
        profilePicture:
            '/files/profile-picture/ESE064DHK_fca3f6c0-56d9-11ee-85e7-bd1f3c78bed5.jpg',
    },
    {
        employeeId: 'EQAE024DHK',
        name: 'Tahsina Islam',
        designation: 'Software Quality Assurance Engineer',
        profilePicture:
            '/files/profile-picture/EQAE024DHK_38414650-8e94-11ee-ad06-65dd9720e22d.jpg',
    },
    {
        employeeId: 'ESE073DHK',
        name: 'Ishmam',
        designation: 'Software Engineer',
        profilePicture:
            '/files/profile-picture/ESE073DHK_7d7e5840-7e11-11ee-9927-a5b2d36ea470.jpg',
    },
    {
        employeeId: 'ELSE015DHK',
        name: 'Shahriar Mohammad',
        designation: 'Software Engineer',
        profilePicture:
            '/root/aladin/ELSE015DHK_7eb97290-c15f-11ed-97fa-1374bddef019.jpg',
    },
    {
        employeeId: 'ELSE003DHK',
        name: 'Shadman Ahmed',
        designation: 'Software Engineer',
    },
    {
        employeeId: 'ELSE002DHK',
        name: 'FM Tanvir Hossain',
        designation: 'Software Engineer',
        profilePicture:
            '/files/profile-picture/ELSE002DHK_14599f80-20a3-11ee-be79-d376901c5dea.jpg',
    },
];

const partners = [
    {
        id: 1,
        name: 'Exabyting',
    },
    {
        id: 2,
        name: 'bKash',
    },
    {
        id: 3,
        name: 'Prevento',
    },
];

const DISTRIBUTION_TYPE = Object.freeze([
    { key: 'leads', label: 'Leads' },
    { key: 'partners', label: 'Partners' },
]);

const ForecastedLeave = () => {
    const [selectedDistributionType, setDistributionType] = useState(
        DISTRIBUTION_TYPE[0]
    );
    const [filterData, setFilterData] = useState(null);

    return (
        <Box {...borderStyle}>
            <ForecastedLeaveDistributionHeader
                distributionType={DISTRIBUTION_TYPE}
                selectedDistributionType={selectedDistributionType}
                setDistributionType={setDistributionType}
            />
            <CustomDropDown
                data={
                    selectedDistributionType === DISTRIBUTION_TYPE[0]
                        ? leads
                        : partners
                }
                placeholder={
                    selectedDistributionType === DISTRIBUTION_TYPE[0]
                        ? 'All Leads'
                        : 'All Patners'
                }
                onChange={setFilterData}
            />

            <BarChart
                width={500}
                height={400}
                data={data}
                margin={{
                    top: 10,
                    right: 30,
                    left: 20,
                    bottom: 100,
                }}
            >
                <CartesianGrid stroke="#ccc" vertical={false} />
                <XAxis dataKey="label">
                    <Label
                        angle={360}
                        position="center"
                        style={{ textAnchor: 'middle' }}
                        dy={40}
                    >
                        Forecasted Leaves
                    </Label>
                </XAxis>
                <YAxis>
                    <Label
                        angle={270}
                        position="left"
                        style={{ textAnchor: 'middle' }}
                    >
                        Employee Count
                    </Label>
                </YAxis>
                <Bar dataKey="employeeCount" fill="red" />

                <Tooltip
                    content={<CustomTooltip name={'ForecastedLeave'} />}
                    cursor={{ fill: 'transparent' }}
                />
            </BarChart>
        </Box>
    );
};

ForecastedLeave.propTypes = {
    forecastedLeaveData: PropTypes.string,
    leadsAndPatnersPicker: PropTypes.string,
};

export default ForecastedLeave;
