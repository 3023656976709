export const EMAIL_REGEX = /^[a-zA-Z0-9.\-_]+@exabyting+\.com$/;
export const EMAIL_REQUIRED_ERROR = 'User Email is required';
export const EMAIL_FORMAT_ERROR = 'Email address format is wrong';

export const PASSWORD_REQUIRED_ERROR = 'Password is required';
export const PASSWORD_LENGTH_ERROR =
    'Password should be in between 8 and 50 characters';

export const EMPLOYEE_ID_REQUIRED_ERROR = 'Employee ID is required';
export const NAME_REQUIRED_ERROR = 'Employee name is required';
export const DESIGNATION_REQUIRED_ERROR = 'Employee designation is required';
export const STATUS_REQUIRED_ERROR = 'Employee status is required...';
export const SUPERVISOR_REQUIRED_ERROR = 'Employee supervisor is required';
export const PARTNER_REQUIRED_ERROR = 'Employee partner is required';

export const TEAM_NAME_REQUIRED_ERROR = 'Team name is required';
export const TEAM_NAME_LENGTH_ERROR =
    'Team name should be in between 3 and 50 characters';
export const TEAM_STATUS_REQUIRED_ERROR = 'Team status is required';

export const MEMBER_NAME_REAUIRED_ERROR = 'Member name is required';
export const MEMBER_NAME_LENGTH_ERROR =
    'Member name should be in between 3 and 50 characters';

export const ADMIN_NAME_REQUIRED_ERROR = 'Admin name is required';
export const ADMIN_NAME_LENGTH_ERROR =
    'Member name should be in between 3 and 50 characters';
export const ADMIN_ROLE_REQUIRED_ERROR = 'Admin role is required';

export const HOLIDAY_NAME_REQUIRED_ERROR = 'Holiday name is required';
export const HOLIDAY_NAME_LENGTH_ERROR =
    'Holiday name should be in between 3 and 50 characters';
