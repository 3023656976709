import React from 'react';

import PropTypes from 'prop-types';

const Tooltip = ({ visible, contents, top, left }) => {
    if (!visible) return null;

    return (
        <div
            className="holiday-tooltip"
            style={{
                top: `${top}px`,
                left: `${left}px`,
            }}
        >
            {contents.map((line, index) => (
                <p key={index}>{line}</p>
            ))}
        </div>
    );
};

Tooltip.propTypes = {
    visible: PropTypes.bool.isRequired,
    contents: PropTypes.arrayOf(PropTypes.string).isRequired,
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
};

export default Tooltip;
