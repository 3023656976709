import React from 'react';

import { Box, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const CustomTooltip = ({ active, payload, name }) => {
    if (active && payload && payload.length) {
        return (
            <Box
                className="custom-tooltip"
                style={{ borderColor: payload[0].payload.fill }}
            >
                {name === 'ForecastedLeave' ? (
                    <Box>
                        <Text
                            style={{
                                color: payload[0].payload.fill,
                                textAlign: 'center',
                            }}
                        >{`${payload[0].payload.employeeCount}`}</Text>
                        <Text style={{ color: payload[0].payload.fill }}>
                            Employee
                        </Text>
                    </Box>
                ) : (
                    <Text style={{ color: payload[0].payload.fill }}>{`${(
                        payload[0].value * 0.01
                    ).toFixed(2)}%`}</Text>
                )}
            </Box>
        );
    }

    return null;
};

CustomTooltip.propTypes = {
    active: PropTypes.any,
    payload: PropTypes.array,
    name: PropTypes.string,
};

export default CustomTooltip;
