import {
    Box,
    Flex,
    Grid,
    GridItem,
    Image,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import leftArrow from '../../assets/icons/arrow-left.svg';
import rightArrow from '../../assets/icons/arrow-right.svg';
import calendar from '../../assets/icons/calendar.svg';
import './styles.css';

const GRID_SIZE = 6;
const START_YEAR = 2016;

const getAllInRange = (start, stop, step) =>
    Array.from(
        { length: (stop - start) / step + 1 },
        (_, i) => start + i * step
    );

const getStartIndex = (years, currentValue) => {
    let currentIndex = years.findIndex(value => value == currentValue);
    return Math.max(currentIndex - GRID_SIZE + 1, 0);
};

const YearPicker = ({ onChange, selectedYear }) => {
    const currentYears = new Date().getFullYear();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const years = getAllInRange(START_YEAR, currentYears + 1, +1);

    const handlePrevClick = () => {
        if (selectedYear > START_YEAR) {
            onChange(selectedYear - 1);
        }
    };

    const handleNextClick = () => {
        if (selectedYear < currentYears + 1) {
            onChange(selectedYear + 1);
        }
    };

    const handleOnClick = year => {
        onChange(year);
        onClose();
    };

    return (
        <Box my="16px">
            <Popover isOpen={isOpen} onClose={onClose}>
                <PopoverTrigger>
                    <Flex
                        direction="row"
                        border="1px"
                        borderRadius="4px"
                        borderColor="#E5E6EB"
                        px="14px"
                        py="8px"
                        onClick={onOpen}
                    >
                        <Image src={calendar} alt="Calender" px="4px" />
                        <Text
                            pt="4px"
                            px="8px"
                            color="#394150"
                            fontSize="14px"
                            fontWeight="500"
                            letterSpacing="0.14px"
                        >
                            {selectedYear === currentYears
                                ? 'Current year'
                                : selectedYear}
                        </Text>
                    </Flex>
                </PopoverTrigger>
                <PopoverContent
                    width="auto"
                    style={{ inset: '0px auto auto -14px' }}
                >
                    <PopoverBody p="12px">
                        <Flex direction="row" justifyContent="space-between">
                            <Image
                                src={leftArrow}
                                alt="left Arrow"
                                cursor="pointer"
                                onClick={handlePrevClick}
                            />
                            <Text color="#212936" fontWeight="700">
                                {selectedYear}
                            </Text>
                            <Image
                                src={rightArrow}
                                alt="right Arrow"
                                cursor="pointer"
                                onClick={handleNextClick}
                            />
                        </Flex>
                        <Grid templateColumns="repeat(3, 1fr)" gap="3" pt="8px">
                            {years
                                .slice(
                                    getStartIndex(years, selectedYear),
                                    getStartIndex(years, selectedYear) +
                                        GRID_SIZE
                                )
                                .map((year, index) => (
                                    <GridItem
                                        key={index}
                                        w="100%"
                                        p="8px"
                                        borderRadius="8px"
                                        textAlign="center"
                                        _hover={{
                                            color: '#4D5461',
                                            backgroundColor: '#E7F6FF',
                                        }}
                                        cursor="pointer"
                                        fontSize="12.5px"
                                        fontWeight="500"
                                        onClick={() => handleOnClick(year)}
                                        className={
                                            selectedYear == year
                                                ? 'active'
                                                : 'text'
                                        }
                                    >
                                        {year}
                                    </GridItem>
                                ))}
                        </Grid>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </Box>
    );
};

YearPicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    selectedYear: PropTypes.any,
    setSelectedYear: PropTypes.any.isRequired,
};

export default YearPicker;
